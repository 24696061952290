.cards__item_image {
  object-fit: cover;
  width: 100%;
  height: 110%;
  position: fixed;
}
.cards {
  padding: 4rem;
  background: #495057;
}
.cards__container {
  display: flex;
  flex-flow: column;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}
.cards__item__pic-wrap {
  position: relative;
  -moz-box-shadow: 0px 0px 5px 2px #282a2d;
  -webkit-box-shadow: 0px 0px 5px 2px #282a2d;
  box-shadow: inset 0 0 5px 2px #282a2d;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
  height: 400px;
}
.cards__item__child {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  overflow: hidden;
  transition: all 0.7s linear;
  opacity: 0.4;
}
.cards__item__child:hover {
  transform: scale(1.4);
  opacity: 1;
}
.cards__item {
  position: relative;
  cursor: pointer;
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  object-fit: contain;
}

.cards__item__info {
  width: 50%;
  margin-top: -80px;
  z-index: 5;
  text-align: center;
}
.cards__item__desc {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}
.cards__item__text {
  color: #fff;
  font-size: 18px;
  font-weight: 400;

  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}
@media screen and (max-width: 736px) {
  .cards {
    height: 230vh !important;
    padding: 4rem;
    background: #495057;
  }
}
@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
  .cards {
    height: 200vh;
    padding: 4rem;
    background: #495057;
  }
}
