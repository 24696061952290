.ana {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #495057;
  margin-top: -100px;
  padding-top: 100px;
  border-top: 0 !important;
}
.image {
  border: 0;
  display: inline-block;
  position: relative;
  object-fit: cover;
}

.image img {
  display: block;
}

.image.left,
.image.right {
  max-width: 30%;
}

.image.left img,
.image.right img {
  width: 100%;
}

.image.left {
  float: left;
  object-fit: cover;

  margin: 0 1.5em 1.25em 0;
  top: 0.25em;
}

.image.right {
  object-fit: cover;

  float: right;
  margin: 0 0 1.25em 1.5em;
  top: 0.25em;
}

.image.fit {
  display: block;
  margin: 0 0 2em 0;
  width: 100%;
}

.image.fit img {
  object-fit: cover;

  width: 100%;
}

.image.main {
  object-fit: cover;

  display: block;
  margin: 2.5em 0;
  width: 100%;
}

.image.main img {
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 736px) {
  .image.main {
    margin: 1.5em 0;
  }
}

.ana article {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-transition: -moz-transform 0.25s ease, opacity 0.25s ease,
    -moz-filter 1s ease, -webkit-filter 1s ease;
  -webkit-transition: -webkit-transform 0.25s ease, opacity 0.25s ease,
    -webkit-filter 1s ease, -webkit-filter 1s ease;
  -ms-transition: -ms-transform 0.25s ease, opacity 0.25s ease,
    -ms-filter 1s ease, -webkit-filter 1s ease;
  transition: transform 0.25s ease, opacity 0.25s ease, filter 1s ease,
    -webkit-filter 1s ease;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: default;
  height: 60vh;
  max-height: 40em;
  min-height: 23em;
  overflow: hidden;
  position: relative;
  width: 40%;
}

.ana article header {
  position: relative;
  z-index: 3;
}

.ana article h3 {
  font-size: 1.75em;
}
.ana article:before {
  transition: opacity 0.5s ease;
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.85;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.image {
  object-fit: contain;
}
.ana article h3 a:hover {
  color: inherit !important;
}

.ana article .link.primary {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
}

.ana article:before {
  -moz-transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.85;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.ana article:after {
  background-color: rgba(36, 41, 67, 0.25);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.ana article:hover:before {
  opacity: 0;
}

.ana article.is-transitioning {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  -moz-filter: blur(0.5em);
  -webkit-filter: blur(0.5em);
  -ms-filter: blur(0.5em);
  filter: blur(0.5em);
  opacity: 0;
}

.ana article:nth-child(4n - 1),
.ana article:nth-child(4n - 2) {
  width: 60%;
}

.ana article:nth-child(6n - 5):before {
  background-color: #adb5bd;
}

.ana article:nth-child(6n - 4):before {
  background-color: #283618;
}

.ana article:nth-child(6n - 3):before {
  background-color: #283618;
}

.ana article:nth-child(6n - 2):before {
  background-color: #adb5bd;
}

.ana article:nth-child(6n - 1):before {
  background-color: #adb5bd;
}

.ana article:nth-child(6n):before {
  background-color: #283618;
}

@media screen and (max-width: 1280px) {
  .ana article {
    height: 30vh;
    max-height: 30em;
    min-height: 20em;
  }
}

@media screen and (max-width: 980px) {
  .ana article {
    width: 50% !important;
  }
}

@media screen and (max-width: 736px) {
  .ana article {
    height: 16em;
    max-height: none;
    min-height: 0;
  }

  .ana article h3 {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 480px) {
  .ana {
    display: block;
  }

  .ana article {
    height: 20em;
    width: 100% !important;
  }
}
