.urunlerimizpage {
  display: flex;
  margin-top: -100px;
  background-color: #495057;
  padding-top: 100px;
}

@media screen and (max-width: 960px) {
  .urunlerimizpagewrap {
    display: flex;
    flex-direction: column;
    margin: 30px;
  }
}

@media screen and (max-width: 768px) {
  .urunlerimizpagewrap {
    display: flex;
    margin: 30px;
  }
  .urunlerimizpage {
    display: flex;
    flex-direction: column;

    margin-top: -100px;
    background-color: #495057;
    padding-top: 100px;
  }
}
