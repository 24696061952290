video {
  object-fit: cover;
  width: 100%;
  height: 110%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 110vh;
  width: 100%;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  background: -webkit-linear-gradient(
    bottom,
    rgba(39, 39, 39, 1) 0%,
    rgba(39, 39, 39, 0.75) 26%,
    rgba(39, 39, 39, 0.2) 100%
  );
  object-fit: contain;
}

.hero-container > h1 {
  color: #6b9080;
  font-size: 100px;
  font-weight: 100;
  margin-top: -100px;
}
.hero-textt {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hero-textt > p {
  text-align: center;
  color: #6b9080;
  font-size: 30px;
  margin-top: 10px;
  font-weight: 100;
}

.hero-cizik {
  width: 80px;
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
  border: 1px solid #6b9080;
}
.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}
.btn-color {
  background: #2d6a4f !important;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 120vh;
    width: 100%;
    margin-top: -150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    background: -webkit-linear-gradient(
      bottom,
      rgba(39, 39, 39, 1) 0%,
      rgba(39, 39, 39, 0.75) 26%,
      rgba(39, 39, 39, 0.2) 100%
    );
    object-fit: contain;
  }

  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
