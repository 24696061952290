.spotlights {
  border-top: 0 !important;
  margin-top: -100px;
  padding-top: 100px;
  background-color: #495057;
}

header.major {
  width: -moz-max-content;
  width: -webkit-max-content;
  width: -ms-max-content;
  width: max-content;
  margin-bottom: 2em;
  color: #ffffff;
  font-size: 1.35rem;
}
.buttonlarge {
  width: 30px;
}
.majortitle {
  font-size: 28px;
  font-weight: 100;
}
.majorp {
  color: #ffffff;

  margin: 0 0 2em 0;
}
header.major > :first-child {
  margin-bottom: 0;
  width: calc(100% + 0.5em);
}

header.major > :first-child:after {
  content: "";
  background-color: #ffffff;
  display: block;
  height: 2px;
  margin: 0.325em 0 0.5em 0;
  width: 100%;
}

header.major > p {
  font-size: 0.7em;
  font-weight: 600;
  letter-spacing: 0.25em;
  margin-bottom: 0;
  text-transform: uppercase;
}

body.is-ie header.major > :first-child:after {
  max-width: 9em;
}

body.is-ie header.major > h1:after {
  max-width: 100% !important;
}

@media screen and (max-width: 736px) {
  header.major > p br {
    display: none;
  }
}

.spotlights + * {
  border-top: 0 !important;
}

.spotlights > section {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: rgb(37, 36, 36);
}

.spotlights > section > .image {
  background-position: center center;
  background-size: cover;
  border-radius: 0;
  display: block;
  position: relative;
  width: 30%;
}

.spotlights > section > .image img {
  border-radius: 0;
  display: block;
  width: 100%;
  height: 600px;
}

.spotlights > section > .image:before {
  background: rgba(36, 41, 67, 0.9);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.spotlights > section > .content {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 14em 3em 0.1em 3em;
  width: 70%;
}

.spotlights > section > .content > .inner {
  margin: 0 auto;
  max-width: 100%;
  width: 65em;
}

.spotlights > section:nth-child(2n) {
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  background-color: black;
}

.spotlights > section:nth-child(2n) > .content {
  -moz-align-items: -moz-flex-end;
  -webkit-align-items: -webkit-flex-end;
  -ms-align-items: -ms-flex-end;
  align-items: flex-end;
}

@media screen and (max-width: 1680px) {
  .spotlights > section > .image {
    width: 40%;
  }

  .spotlights > section > .content {
    width: 60%;
  }
}

@media screen and (max-width: 1280px) {
  .spotlights > section > .image {
    width: 45%;
  }
  .majortitle {
    font-size: 30px;
    font-weight: 100;
  }
  .spotlights > section > .content {
    width: 55%;
  }
}

@media screen and (max-width: 980px) {
  .spotlights > section {
    display: block;
  }

  .spotlights > section > .image {
    width: 100%;
  }

  .spotlights > section > .content {
    padding: 4em 3em 2em 3em;
    width: 100%;
  }
}

@media screen and (max-width: 736px) {
  .spotlights > section > .content {
    padding: 3em 1.5em 1em 1.5em;
  }
  .majortitle {
    font-size: 18px;
    font-weight: 100;
  }
}
